<template>
  <div id="app">
    <div class="scroll-up" @click="scrollUp" v-if="$store.state.isShowScrollUp"><img src="/assets/img/back-to-top.png" alt=""></div>
    <div ref="modalCover" class="modal-cover hide" @click="closeModal()">
      <div class="modal-content service-desc-modal" @click.stop>
        <div class="modal-header">
          <h2>damda를 통해 어떤 서비스를 만들 수 있나요?</h2>
          <div @click="closeModal()">
            <img
              src="/assets/img/close-icon.png"
              alt=""
              style="cursor: pointer;"
            />
          </div>
        </div>
        <div class="modal-body">
          <div>
            <div class="ex-title">01.</div>
            <div>LG전자 가전 12종 TV 기능을<br />제어할 수 있습니다.</div>
            <div class="ex-img">
              <img src="/assets/img/service-ex-air.png" alt="" />
            </div>
            <div class="sub-desc">(예시)<br />"공기청정기 켜기"</div>
          </div>
          <div>
            <div class="ex-title">02.</div>
            <div>
              제품에 없던 기능을 추가하거나,<br />기존 기능을 업그레이드할 수
              있습니다.
            </div>
            <div class="ex-img">
              <img src="/assets/img/service-ex-tv.png" alt="" />
            </div>
            <div class="sub-desc">
              (예시)<br />"TV 시청 데이터 실시간 이력 보기"<br />"TV화면 캡쳐"
            </div>
          </div>
        </div>
      </div>
    </div>
    <Header />
    <div ref="dimCover" class="dim-cover hide"></div>
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    isDimCoverOpen() {
      return this.$store.state.isDimCoverOpen;
    },
    isModalCoverOpen() {
      return this.$store.state.isModalCoverOpen;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("setModalCoverState", false);
    },

    scrollUp() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    isDimCoverOpen(isOpen) {
      if (isOpen) {
        this.$refs.dimCover.classList.remove("hide");
      } else {
        this.$refs.dimCover.classList.add("hide");
      }
    },
    isModalCoverOpen(isOpen) {
      if (isOpen) {
        this.$refs.modalCover.classList.remove("hide");
      } else {
        this.$refs.modalCover.classList.add("hide");
      }
    },
  },
};
</script>

<style lang="less">
h2 {
  margin: 0;
}

.modal-content {
  background-color: #fff;
  z-index: 1600;
  border-radius: 20px;
  overflow: hidden;
}

.service-desc-modal {
  .modal-header {
    background-color: #54bdff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
  }

  .modal-body {
    display: flex;
    padding: 40px;
    gap: 84px;
  }

  .ex-title {
    font-weight: bold;
    color: rgba(34, 34, 34, 0.2);
    font-size: 36px;
    margin-bottom: 20px;
  }

  .sub-desc {
    font-size: 14px;
    color: #666;
    letter-spacing: -0.38px;
    line-height: 24px;
  }

  .ex-img {
    margin: 20px 0;
  }
}
</style>
