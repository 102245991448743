<template>
  <footer>
    <div class="container row-between-center">
      <span class="row ai-center">
        <img src="/assets/img/footer-lg.png" alt="" />
        <span class="gray-text" style="font-size: 14px; margin-left: 47px">
          Copyright ©2021 LG Electronics. All Rights Reserved.
        </span>
      </span>
      <a
        style="display: inline-flex; text-decoration: none; color: inherit"
        class="ai-center"
        href="https://opensource.lge.com"
      >
        <span>Open Source</span>
        <img src="/assets/img/external-link.svg" style="margin-left: 6px"
      /></a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
