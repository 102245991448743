<template>
  <header>
    <div class="container">
      <nav class="navbar row-between-center">
        <router-link to="/" class="brand">
          <strong>damda</strong>
          &nbsp;Developers
        </router-link>
        <nav class="gnb-container">
          <nav class="gnb">
            <router-link to="/get-started" @mouseenter.native="closeDrawer()"
              ><span>get Started</span></router-link
            >
            <router-link
              to="/docs"
              @mouseenter.native="openDrawer()"
              @click.native.capture="
                {
                  $event.preventDefault();
                }
              "
              ><span>Docs</span></router-link
            >
          </nav>
          <a
            @mouseenter="closeDrawer()"
            class="damda-console-btn"
            href="https://damda.lge.com"
            target="_blank"
            >damda Console<img src="/assets/img/north_east_white_24dp.svg"
          /></a>
        </nav>
      </nav>
    </div>
    <div
      class="drawer hide"
      @mouseleave="closeDrawer()"
      ref="drawer"
      @click="closeDrawer()"
    >
      <div class="rest"></div>
      <div class="left-container">
        <div class="drawer-menu-title">webOS TV (Local)</div>
        <nav>
          <router-link to="/docs/webos-tv/api">API Call Sequence</router-link>
          <router-link to="/docs/webos-tv/reference">Reference</router-link>
        </nav>
      </div>
      <div class="center-container" style="padding-left: 78px;">
        <div class="drawer-menu-title">ThinQ 가전 (Cloud)</div>
        <nav>
          <router-link to="/docs/thinq/api">API Call Sequence</router-link>
          <router-link to="/docs/thinq/reference">Reference</router-link>
          <router-link to="/docs/thinq/profile">가전 Profile</router-link>
        </nav>
      </div>
      <div class="right-nav-container"  style="padding-left: 78px;">
        <div class="drawer-menu-title">음성 + NLU (Beta)</div>
        <nav>
          <router-link to="/docs/voice/reference">Reference</router-link>
        </nav>
      </div>
      <div class="rest"></div>
    </div>
  </header>
</template>
<script>
export default {
  methods: {
    openDrawer() {
      this.$refs.drawer.classList.remove("hide");
      this.$store.commit("setDimCoverState", true);
    },
    closeDrawer() {
      this.$refs.drawer.classList.add("hide");
      this.$store.commit("setDimCoverState", false);
    },
  },
};
</script>
