<template>
  <main>
    <section style="background-color: #54bdff;">
      <div class="container row-between-center">
        <div class="home-cover-text">
          <div class="">생각을<span class="accent-blue"> 담다.</span></div>
          <div class="">아이디어를<span class="accent-blue"> 담다.</span></div>
          <div class="">무엇이든<span class="accent-blue"> 담다.</span></div>
        </div>
        <img style="margin-top: 84.5px;" src="/assets/img/main-cover.svg"/>
      </div>
    </section>
    <section class="row-between-center container section-inner">
      <div class="damda-service-desc">
        damda는 우리가 상상하는 무엇이든 빠르게 개발하고 실행해 볼 수 있는 개발자를 위한 공간입니다. LG 제품과의 통신은 물론 제어까지, 기기를 통해 제공할 다양한 서비스들을 직접 만들고 경험해 보세요.
      </div>
      <router-link class="damda-start-btn accent-blue" to="/get-started"
      >damda 시작하기<img src="/assets/img/arrow_forward_ios_blue_24dp.svg"
      /></router-link>
    </section>
    <section class="container section-inner" style="padding-top: 0;">
      <div class="cover-h1">damda 사용법</div>
      <div class="row" style="flex-wrap: wrap; row-gap: 100px;">
        <div class="cover-usage-card">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법01.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">
            01. damda Developers 살펴보기
          </div>
          <div class="cover-usage-card-desc" style="width: 245px;">
            LG TV나 가전 연동 서비스를 개발하는데 필요한 친절한 가이드와 API
            문서를 제공합니다.
          </div>
        </div>
        <div class="cover-usage-arrow-item">
          <img src="/assets/img/usage-card-arrow.svg"/>
        </div>
        <div class="cover-usage-card">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법02.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">02. 아이디어 상상하기</div>
          <div class="cover-usage-card-desc" style="width: 279px;">
            damda Developers 제공 정보를 토대로 재미있는 서비스/앱 아이디어를
            상상해봅니다.
          </div>
          <a href="" @click.prevent="openServiceDescModal()">damda를 통해 어떤 서비스를 만들 수 있나요?</a>
        </div>
        <div class="cover-usage-arrow-item">
          <img src="/assets/img/usage-card-arrow.svg"/>
        </div>
        <div class="cover-usage-card" style="margin-right: 30px;">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법03.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">03. 서비스/앱 개발하기</div>
          <div class="cover-usage-card-desc" style="width: 256px;">
            마음에 드는 아이디어를 정했다면 damda 가이드와 API를 활용해 개발을
            시작합니다.
          </div>
        </div>
        <div class="cover-usage-arrow-item">
          <img src="/assets/img/usage-card-arrow.svg"/>
        </div>
        <div class="cover-usage-card">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법04.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">
            04. damda콘솔에 코드 업로드하기
          </div>
          <div class="cover-usage-card-desc" style="width: 267px;">
            완성된 코드를 damda 콘솔에 업로드합니다.
          </div>
        </div>
        <div class="cover-usage-arrow-item">
          <img src="/assets/img/usage-card-arrow.svg"/>
        </div>
        <div class="cover-usage-card">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법05.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">
            05. damda콘솔에서 서비스 배포하기
          </div>
          <div class="cover-usage-card-desc" style="width: 279px;">
            원하는 기기를 지정해 서비스/앱을 배포합니다.
          </div>
        </div>
        <div class="cover-usage-arrow-item">
          <img src="/assets/img/usage-card-arrow.svg"/>
        </div>
        <div class="cover-usage-card">
          <div class="cover-usage-card-img">
            <div class="bg">
              <img src="/assets/img/사용법06.png"/>
            </div>
          </div>
          <div class="cover-usage-card-title">
            06. 대상 기기에 서비스 설치 완료
          </div>
          <div class="cover-usage-card-desc" style="width: 233px;">
            이제 제품에서 나의 서비스를 사용할 수 있습니다.
          </div>
        </div>
      </div>
    </section>
    <section style="background-color: #fafafa;" class="section-inner">
      <div class="container">
        <div class="cover-h1" style="line-height: 1.4;">
          damda 플랫폼은 LG 가전에서 동작하는 실행 환경과 누구나 쉽게<br/>
          애플리케이션을 개발해 제품에 배포할 수 있도록 지원하는 클라우드
          서비스를 제공합니다.
        </div>
        <ul class="damda-features">
          <li class="damda-feature-card">
            <div
                style="background-color: #66ccff;"
                class="damda-feature-card-cover"
            >
              <img
                  src="/assets/img/플랫폼01.svg"
                  class="abs"
                  style="top: 23px; left: 42px;"
              />
            </div>
            <div class="damda-feature-card-body">
              <div class="damda-feature-card-title cover-h2">
                클라우드 기반의 쉽고 빠른 애플리케이션 배포
              </div>
              <div class="damda-feature-card-desc">
                damda는 컴포넌트와 배포할 타겟 디바이스를 클라우드로 관리하여
                쉽고 빠르게 애플리케이션을 배포할 수 있습니다.
              </div>
            </div>
          </li>
          <li class="damda-feature-card">
            <div
                style="background-color: #a6ed5f;"
                class="damda-feature-card-cover"
            >
              <img
                  src="/assets/img/플랫폼02.svg"
                  class="abs"
                  style="top: -6px; left: 116px;"
              />
            </div>
            <div class="damda-feature-card-body">
              <div class="damda-feature-card-title cover-h2">
                다양한 LG 제품 연동 지원
              </div>
              <div class="damda-feature-card-desc">
                damda는 12가지 LG가전을 연동할 수 있는 ThinQ 컴포넌트와 webOS TV
                컴포넌트를 Public 컴포넌트로 제공하고 있습니다. 오픈소스로
                운영하며 더 많은 연동 기능을 업데이트할 예정입니다.
              </div>
            </div>
          </li>
          <li class="damda-feature-card">
            <div
                style="background-color: #ffea80;"
                class="damda-feature-card-cover"
            >
              <img
                  src="/assets/img/플랫폼03.svg"
                  class="abs"
                  style="top: 5px; left: 95px;"
              />
            </div>
            <div class="damda-feature-card-body">
              <div class="damda-feature-card-title cover-h2">
                개발 보조 콘솔 제공
              </div>
              <div class="damda-feature-card-desc">
                damda는 개발자들이 쉽게 자신이 배포한 애플리케이션을 디버깅 할
                수 있도록 개발 보조 콘솔을 제공합니다. 개발 보조 콘솔에서는
                배포된 애플리케이션의 로그와 리소스를 모니터링할 수 있습니다.
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="rel">
      <div style="background-color: #73c9ff; padding-bottom: 165px;">
        <div class="container">
          <div class="cover-h1" style="padding-top: 100px;">
            damda 플랫폼은 3가지 요소로 구성됩니다.
          </div>
          <div style="text-align: center;">
            <img src="/assets/img/home-service-componet-desc.svg"/>
          </div>
        </div>
      </div>
      <ul class="damda-components container">
        <li class="damda-component-card">
          <div class="damda-component-cover">
            <div class="damda-component-card-title">개발 PC</div>
            <img
                src="/assets/img/home-code.png"
                class="damda-component-card-icon"
            />
          </div>
          <div class="damda-component-card-desc">
            damda 컴포넌트를 생성하기 위해 필요한 애플리케이션 소스 코드를
            개발하는 PC입니다.
          </div>
        </li>
        <li class="damda-component-card">
          <div class="damda-component-cover">
            <div class="damda-component-card-title">damda 클라우드</div>
            <img
                src="/assets/img/home-cloud.png"
                class="damda-component-card-icon"
            />
          </div>
          <div class="damda-component-card-desc">
            컴포넌트와 damda 디바이스를 관리하며 배포 기능을 지원합니다.
          </div>
        </li>
        <li class="damda-component-card">
          <div class="damda-component-cover">
            <div class="damda-component-card-title">damda 디바이스</div>
            <img
                src="/assets/img/home-device.png"
                class="damda-component-card-icon"
            />
          </div>
          <div class="damda-component-card-desc">
            damda 플랫폼이 탑재된 디바이스로서, LG 가전, IoT 허브와 같은
            기기들이 damda 디바이스가 될 수 있습니다.
          </div>
        </li>
      </ul>
      <div class="container" style="margin-top: 220px;">
        <div class="damda-component-desc">
          damda 플랫폼을 이용한 작업은 개발 PC에서 damda 디바이스로 실행할
          애플리케이션을 개발하는 것으로 시작합니다.<br/>개발이 완료되면 damda
          개발자는 damda Console을 통하여 컴포넌트를 생성하게 됩니다.<br/>컴포넌트는
          damda 클라우드에서 damda 디바이스로 실행할 애플리케이션의 소스코드,
          그리고 이를 빌드하고 실행할 수 있는 스크립트 등을 관리하는 소프트웨어
          모듈입니다.<br/><br/>
          컴포넌트에는 두 가지 종류가 있습니다.
        </div>
        <div ref="homeDetail" class="hide">
          <div class="damda-component-h2">Public 컴포넌트</div>
          <div class="damda-component-desc">
            <ul>
              <li>
                damda를 이용하는 모든 개발자에게 기본 제공되는 Public 컴포넌트
              </li>
              <li>damda 플랫폼 관리자가 배포 및 관리</li>
              <li>지원 중인 컴포넌트 : webOS TV와 ThinQ 가전 컴포넌트</li>
            </ul>
            <div class="damda-component-desc-note">
              <img src="/assets/img/question-icon.svg" class="note-icon"/>
              앞으로 더 다양한 컴포넌트를 업데이트하고 오픈소스로 지원할
              예정입니다.
            </div>
          </div>
          <div class="damda-component-h2">Custom 컴포넌트</div>
          <div class="damda-component-desc">
            <ul>
              <li>damda를 이용하는 개발자가 직접 생성한 컴포넌트</li>
              <li>컴포넌트를 생성한 개발자만 조회 및 배포 가능</li>
              <li>Web/스크립트 2가지 형식 지원</li>
            </ul>
            배포 방식은 대상에 따라 두 가지로 구분됩니다.
          </div>
          <hr style="margin-top: 60px;border: 1px solid #ddd;">
          <div class="damda-component-h2">개별 배포</div>
          <div class="damda-component-desc">
            <ul>
              <li>특정 damda 디바이스를 개별 지정하여 컴포넌트를 배포</li>
              <li>기대효과</li>
              <ul class="hyphen-ul">
                <li>damda 디바이스별 개인화 가능</li>
                <li>
                  앱스토어처럼 개인이 원하는 앱만 디바이스에 설치하고 싶을 때
                  적용할 수 있는 방법
                </li>
              </ul>
              <li>Web/스크립트 2가지 형식 지원</li>
            </ul>
          </div>
          <div class="damda-component-h2">그룹 배포</div>
          <div class="damda-component-desc">
            <ul>
              <li>
                여러 대의 damda 디바이스를 대상으로 동시에 컴포넌트를 배포
              </li>
              <li>기대효과</li>
              <ul class="hyphen-ul">
                <li>
                  damda 플랫폼을 지원하는 기기인 경우, 이미 시장에 출시되었더라도
                  FOTA나 현장 방문없이 기기에 새로운 앱을 Release하거나 기존 앱
                  삭제 및 업데이트 가능
                </li>
                <li>
                  구형 제품에 대한 Maintenance 난이도를 낮추는 한편, 기능
                  업데이트도 해줄 수 있음
                </li>
              </ul>
            </ul>
            <div class="damda-component-desc-note">
              <img src="/assets/img/question-icon.svg" class="note-icon"/>
              현재 damda 디바이스 전체 단위로만 그룹 선택이 가능합니다. 향후
              Custom 그룹 기능을 추가할 예정입니다.
            </div>
          </div>
          <div class="damda-component-desc">
            컴포넌트의 배포까지 완료되면 damda 디바이스 상에서 애플리케이션이
            실행되는 것을 확인할 수 있습니다.
          </div>
        </div>
      </div>
    </section>
    <nav class="cover-tail-nav">
      <div @click="showDetail()" v-if="!isDetailOpen">
        <div style="margin-bottom: 10px;" class="accent-blue">
          더 자세히 알아보기
        </div>
        <img src="/assets/img/plus.svg" alt=""/>
      </div>
      <div style="margin-top: 20px;" @click="hideDetail()" v-if="isDetailOpen">
        <div style="margin-bottom: 10px;">접기</div>
        <img src="/assets/img/x.svg" alt=""/>
      </div>
    </nav>
  </main>
</template>

<script>

export default {
  data() {
    return {
      isDetailOpen: false
    }
  },
  methods: {
    hideDetail() {
      this.$refs.homeDetail.classList.add('hide');
      this.isDetailOpen = false;
    },
    showDetail() {
      this.$refs.homeDetail.classList.remove('hide');
      this.isDetailOpen = true;
    },
    openServiceDescModal() {
      this.$store.commit('setModalCoverState', true);
    }
  }
};
</script>

<style>
.cover-tail-nav {
  cursor: pointer;
}
</style>
