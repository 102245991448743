import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/get-started",
    component: () => import("../views/get-started"),
    children: [
      {
        path: "",
        name: "GetStartedHome",
        component: () => import("../views/get-started/Home.vue"),
        meta: { title: "시작하기" },
      },
      {
        path: "configuration",
        name: "GetStartedPage1",
        component: () => import("../views/get-started/Page1.vue"),
        meta: { title: "개발환경설정" },
      },
      {
        path: "example",
        name: "GetStartedPage2",
        component: () => import("../views/get-started/Page2.vue"),
        meta: { title: "예제1" },
      },
      {
        path: "example2",
        name: "GetStartedPage3",
        component: () => import("../views/get-started/Page3.vue"),
        meta: { title: "예제2" },
      },
      {
        path: "example3",
        name: "GetStartedPage4",
        component: () => import("../views/get-started/Page4.vue"),
        meta: { title: "예제3" },
      },
      {
        path: "example4",
        name: "GetStartedPage5",
        component: () => import("../views/get-started/Page5.vue"),
        meta: { title: "예제4" },
      },
      {
        path: "example5",
        name: "GetStartedPage6",
        component: () => import("../views/get-started/Page6.vue"),
        meta: { title: "예제5" },
      },
    ],
  },
  {
    path: "/docs",
    component: () => import("../views/docs"),
    children: [
      {
        path: "",
        redirect: "/docs/webos-tv",
      },
      {
        path: "webos-tv",
        component: () => import("../views/docs/webos-tv"),
        children: [
          {
            path: "",
            redirect: "/docs/webos-tv/api",
          },
          {
            path: "api",
            component: () => import("../views/docs/webos-tv/Api.vue"),
            meta: { title: "WebOS TV Overview" },
          },
          {
            path: "reference",
            component: () => import("../views/docs/webos-tv/Reference.vue"),
            meta: { title: "WebOS TV API" },
          },
        ],
      },
      {
        path: "thinq",
        component: () => import("../views/docs/thinq"),
        children: [
          {
            path: "",
            redirect: "/docs/thinq/api",
          },
          {
            path: "api",
            component: () => import("../views/docs/thinq/Api.vue"),
            meta: { title: "Thinq Overview" },
          },
          {
            path: "reference",
            component: () => import("../views/docs/thinq/Reference.vue"),
            meta: { title: "Thinq API" },
          },
          {
            path: "profile",
            component: () => import("../views/docs/thinq/Profile.vue"),
            children: [
              {
                path: "",
                redirect: "/docs/thinq/profile/washer",
              },
              {
                path: "washer",
                component: () =>
                  import("../views/docs/thinq/profile/Washer.vue"),
                meta: { title: "Thinq Washer API" },
              },
              {
                path: "dryer",
                component: () =>
                  import("../views/docs/thinq/profile/Dryer.vue"),
                meta: { title: "Thinq Dryer API" },
              },
              {
                path: "styler",
                component: () =>
                  import("../views/docs/thinq/profile/Styler.vue"),
                meta: { title: "Thinq Styler API" },
              },
              {
                path: "refrigerator",
                component: () =>
                  import("../views/docs/thinq/profile/Refrigerator.vue"),
                meta: { title: "Thinq Refrigerator API" },
              },
              {
                path: "dishwasher",
                component: () =>
                  import("../views/docs/thinq/profile/Dishwasher.vue"),
                meta: { title: "Thinq Dishwasher API" },
              },
              {
                path: "oven",
                component: () => import("../views/docs/thinq/profile/Oven.vue"),
                meta: { title: "Thinq Oven API" },
              },
              {
                path: "waterpurifier",
                component: () =>
                  import("../views/docs/thinq/profile/Waterpurifier.vue"),
                meta: { title: "Thinq Waterpurifier API" },
              },
              {
                path: "airconditioner",
                component: () =>
                  import("../views/docs/thinq/profile/Airconditioner.vue"),
                meta: { title: "Thinq Airconditioner API" },
              },
              {
                path: "airpurifier",
                component: () =>
                  import("../views/docs/thinq/profile/Airpurifier.vue"),
                meta: { title: "Thinq Airpurifier API" },
              },
              {
                path: "dehumidifier",
                component: () =>
                  import("../views/docs/thinq/profile/Dehumidifier.vue"),
                meta: { title: "Thinq Dehumidifier API" },
              },
              {
                path: "ceilingFan",
                component: () =>
                  import("../views/docs/thinq/profile/CeilingFan.vue"),
                meta: { title: "Thinq CeilingFan API" },
              },
              {
                path: "robotcleaner",
                component: () =>
                  import("../views/docs/thinq/profile/Robotcleaner.vue"),
                meta: { title: "Thinq Robotcleaner API" },
              },
              {
                path: "aqaraSensors",
                component: () =>
                  import("../views/docs/thinq/profile/AqaraSensors.vue"),
                meta: { title: "Thinq AqaraSensors API" },
              },
            ],
          },
        ],
      },
      {
        path: "voice",
        component: () => import("../views/docs/voice"),
        children: [
          {
            path: "",
            redirect: "/docs/voice/reference",
          },
          {
            path: "reference",
            component: () => import("../views/docs/voice/Reference.vue"),
            meta: { title: "Voice API" },
          },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 130 } };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to, from) => {
  const titleList = [];
  titleList.push("LG damda");
  if (to.meta.title) {
    titleList.push(to.meta.title);
  }
  document.title = titleList.join(" | ");
});

export default router;
